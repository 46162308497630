exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html,\nbody,\n.index_app_3Qs6X {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100%;\n    height: 100%;\n    margin: 0;\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 1024px;\n    min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* { -webkit-box-sizing: border-box; box-sizing: border-box; }\n\n/*\n  .scratchCategoryId-looks .scratchCategoryItemBubble::after {\n    background-image: url(icons/looks_icon.svg);\n  }\n\n  .scratchCategoryId-sound .scratchCategoryItemBubble::after {\n    background-image: url(icons/sound_icon.svg);\n  }\n\n  .scratchCategoryId-events .scratchCategoryItemBubble::after {\n    background-image: url(icons/events_icon.svg);\n  }\n\n  .scratchCategoryId-control .scratchCategoryItemBubble::after {\n    background-image: url(icons/control_icon.svg);\n  }\n\n  .scratchCategoryId-sensing .scratchCategoryItemBubble::after {\n    background-image: url(icons/sensing_icon.svg);\n  }\n\n  .scratchCategoryId-operators .scratchCategoryItemBubble::after {\n    background-image: url(icons/operators_icon.svg);\n  }\n\n  .scratchCategoryId-variables .scratchCategoryItemBubble::after {\n    background-image: url(icons/variables_icon.svg);\n  }\n\n  .scratchCategoryId-lists .scratchCategoryItemBubble::after {\n    background-image: url(icons/list_icon.svg);\n  }\n\n  .scratchCategoryId-myBlocks .scratchCategoryItemBubble::after {\n    background-image: url(icons/block_icon.svg);\n  } */\n", ""]);

// exports
exports.locals = {
	"app": "index_app_3Qs6X"
};